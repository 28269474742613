import axios from 'axios';

let prefix = "/arbitrage-server"

const ArbitrageServerApi = {
	
	listArbitrageServer(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	archiveArbitrageServer(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createArbitrageServer(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateArbitrageServer(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default ArbitrageServerApi;