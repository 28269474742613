import axios from 'axios';

let prefix = "/direct-referral"

const DirectReferralApi = {
	getDirectReferralUplineTree(payload){
		return axios.get( prefix + "/list-upline", {params: payload})
	},
	getDirectReferralDownlineTree(payload){
		return axios.get( prefix + "/list-downline", {params: payload})
	},
	getDirectReferralCommunityDashboard(payload){
		return axios.get( prefix + "/community-dashboard-data", {params: payload})
	}
}

export default DirectReferralApi;