import axios from 'axios';

let prefix = "/poster"

const PosterApi = {
	
	getPosterList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	archivePoster(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createPoster(payload){
		return axios.post( prefix + "/create", payload)
	},
	updatePoster(payload){
		return axios.post( prefix + "/update", payload)
	},
	setPosterImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearPosterImage(payload){
		return axios.delete( prefix + "/delete-image", {params: payload})
	},
}

export default PosterApi;