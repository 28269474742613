import axios from 'axios';

let prefix = "/nationality"

const NationalityApi = {
	
	getNationalityList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default NationalityApi;