import axios from 'axios';

let prefix = "/video-testimony"

const VideoTestimonyApi = {
	
	getVideoTestimonyList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	updateVideoTestimony(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveVideoTestimony(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
}

export default VideoTestimonyApi;