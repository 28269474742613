import axios from 'axios';

let prefix = "/user-asset-history"

const UserAssetHistoryApi = {
	
	getUserAssetHistoryList(payload){
		return axios.get( prefix + "/list-admin", {params: payload})
	},
	getTypeRef(){
		return axios.get( prefix + "/refs")
	},
}

export default UserAssetHistoryApi;