import axios from 'axios';

let prefix = "/id-verification"

const IdVerificationApi = {
	
	getIdVerificationList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	updateIdVerification(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveIdVerification(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
}

export default IdVerificationApi;