import axios from 'axios';

let prefix = "/wallet-transaction"

const WithdrawalApprovalApi = {
	
	getWithdrawalApprovalList(payload){
		return axios.get( prefix + "/list-approval", {params: payload})
	},
	updateWithdrawalApproval(payload){
		return axios.post( prefix + "/update-approval", payload)
	},
	getWithdrawalTypes(){
		return axios.get( prefix + "/withdrawal-types")
	}
}

export default WithdrawalApprovalApi;