import axios from 'axios';

let prefix = "/auth"

const AuthApi = {
	resetPassword(payload){
		return axios.post( prefix + "/verify/reset-password", payload)
	},
	requestOtp(payload){
		return axios.post( prefix + "/request-otp", payload)
	},
	checkReferralCode(payload){
		return axios.get( prefix + "/check-referral-code", {params: payload})
	},
	register(payload){
		return axios.post( prefix + "/register-from-referral-code", payload)
	},
	getMobileOtp(payload){
		return axios.get( prefix + "/get-otp", {params: payload})
	},
	getEmailOtp(payload){
		return axios.post( prefix + "/request-otp", payload)
	},
}

export default AuthApi;