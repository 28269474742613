import axios from 'axios';

let prefix = "/user-asset-balance"

const UserAssetBalanceApi = {
	
	getUserAssetBalanceList(payload){
		return axios.get( prefix + "/list-admin", {params: payload})
	},
}

export default UserAssetBalanceApi;